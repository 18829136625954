var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-modal',{attrs:{"width":"1200px","centered":"","mask-closable":false,"ok-button-props":{ props: { loading: _vm.submitting } },"title":"新增加油包"},on:{"ok":_vm.handleSubmit},model:{value:(_vm.isShow),callback:function ($$v) {_vm.isShow=$$v},expression:"isShow"}},[_c('a-form',{staticClass:"simiot-compact-form",attrs:{"form":_vm.form,"label-col":{ span: 6 },"wrapper-col":{ span: 15 }},on:{"submit":_vm.handleSubmit}},[_c('a-row',[_c('a-col',{attrs:{"span":12}},[_c('span',{staticClass:"item-title"},[_vm._v("基本信息")]),_c('a-form-item',{attrs:{"label":"加油包名称"}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:(['name', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入加油包名称' },
                  { max: 100, message: '最多100个字符' } ]
              }]),expression:"['name', {\n                normalize: this.$lodash.trim,\n                rules: [\n                  { required: true, message: '请输入加油包名称' },\n                  { max: 100, message: '最多100个字符' },\n                ]\n              }]"}]})],1),_c('a-form-item',{attrs:{"label":"加油包类型"}},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['product_type',
                            { initialValue: '',
                              rules: [{ required: true, message: '请选择加油包类型' }]
                            } ]),expression:"['product_type',\n                            { initialValue: '',\n                              rules: [{ required: true, message: '请选择加油包类型' }]\n                            },\n              ]"}],attrs:{"allow-clear":""}},[_c('a-select-option',{attrs:{"value":"addon_flow"}},[_vm._v(" 流量包 ")]),_c('a-select-option',{attrs:{"value":"addon_voice"}},[_vm._v(" 语音包 ")])],1)],1),(_vm.productType == 'addon_flow')?_c('a-form-item',{staticStyle:{"margin-bottom":"0px"},attrs:{"label":"加油包容量","required":"required"}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-form-item',{style:({ display: 'inline-block', width: '80%' })},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([ 'package_capacity', {
                      rules: [
                        { required: true, message: '请输入流量包容量' },
                        _vm.packageCapacityTypeRule
                      ],
                    }]),expression:"[ 'package_capacity', {\n                      rules: [\n                        { required: true, message: '请输入流量包容量' },\n                        packageCapacityTypeRule\n                      ],\n                    }]"}],staticStyle:{"width":"280px"},attrs:{"min":0.00,"max":10000000}})],1)],1),_c('a-form-item',{style:({ display: 'inline-block', width: '20%' })},[_c('a-select',{directives:[{name:"decorator",rawName:"v-decorator",value:(['capacity_unit', { initialValue: 'mb' }]),expression:"['capacity_unit', { initialValue: 'mb' }]"}],staticStyle:{"width":"65px"},on:{"change":_vm.handleCapacityUnitChange}},_vm._l((_vm.capacityUnitOptions),function(option){return _c('a-select-option',{key:option.value,attrs:{"value":option.value}},[_vm._v(" "+_vm._s(option.name)+" ")])}),1)],1)],1)],1):_vm._e(),(_vm.productType == 'addon_voice')?_c('a-form-item',{staticStyle:{"margin-bottom":"0px"},attrs:{"label":"加油包语音","required":"required"}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-form-item',{style:({ display: 'inline-block', width: '90%', marginBottom: 0 })},[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:(['voice_capacity', {
                      rules: [
                        { required: _vm.isHasVoiceFunction, message: '请输入语音时长' }
                      ]
                    }]),expression:"['voice_capacity', {\n                      rules: [\n                        { required: isHasVoiceFunction, message: '请输入语音时长' }\n                      ]\n                    }]"}],staticStyle:{"width":"260px"},attrs:{"min":1,"max":999999,"step":1,"precision":0}}),_c('span',{staticStyle:{"margin-left":"5px"}},[_vm._v("分钟")])],1)],1)],1)],1):_vm._e(),_c('div',[_c('span',{staticClass:"item-title"},[_vm._v("零售设置")]),_c('a-form-item',{attrs:{"label":"允许零售"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_allow_retail', { valuePropName: 'checked' }]),expression:"['is_allow_retail', { valuePropName: 'checked' }]"}]}),_vm._v("允许移动端订购 ")],1),_c('a-form-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isAllowRetail),expression:"isAllowRetail"}],attrs:{"label":"零售价设置者"}},[_c('a-radio-group',{directives:[{name:"decorator",rawName:"v-decorator",value:(['retail_operator', {
                  initialValue: 'user',
                  rules: [
                    { required: _vm.isAllowRetail, message: '请选择零售价设置者' } ]
                }]),expression:"['retail_operator', {\n                  initialValue: 'user',\n                  rules: [\n                    { required: isAllowRetail, message: '请选择零售价设置者' },\n                  ]\n                }]"}]},[_c('a-radio',{attrs:{"value":"platform"}},[_vm._v(" 平台 ")]),_c('a-radio',{attrs:{"value":"user"}},[_vm._v(" 零售商 ")])],1)],1)],1),_c('div',[_c('span',{staticClass:"item-title"},[_vm._v("其它设置")]),_c('a-form-item',{attrs:{"label":"允许自订"}},[_c('a-checkbox',{directives:[{name:"decorator",rawName:"v-decorator",value:(['is_allow_self_subscribe', { valuePropName: 'checked' }]),expression:"['is_allow_self_subscribe', { valuePropName: 'checked' }]"}]}),_vm._v(" 允许CMP订购 ")],1)],1)],1),_c('a-col',{attrs:{"span":12}},[_c('span',{staticClass:"item-title"},[_vm._v("价格设置")]),_c('a-row',{staticStyle:{"margin-top":"10px","margin-bottom":"10px"},attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":_vm.favorablePriceSpanSize}},[_vm._v(" 周期数* ")]),_c('a-col',{attrs:{"span":_vm.favorablePriceSpanSize}},[_vm._v(" 标准价(元)* ")]),_c('a-col',{attrs:{"span":_vm.favorablePriceSpanSize}},[_vm._v(" 底价(元)* ")]),(_vm.isAllowSetRetailPrice)?_c('a-col',{attrs:{"span":_vm.favorablePriceSpanSize}},[_vm._v(" 零售价(元) ")]):_vm._e()],1),_vm._l((_vm.form.getFieldValue('keys')),function(k){return _c('a-row',{key:k,attrs:{"gutter":24}},[_c('a-col',{attrs:{"span":_vm.favorablePriceSpanSize}},[_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([("base_product_favorable_prices[" + k + "].cycles"), {
                    initialValue: k === 0 ? 1 : undefined,
                    rules: [
                      { required: true, message: '请输入周期数' },
                      { type: 'integer', message: '请输入 1 - 10000000 之间的整数' },
                      { validator: function (rule, value, callback) { return _vm.checkCycles(rule, value, callback, k); } }
                    ]
                  }]),expression:"[`base_product_favorable_prices[${k}].cycles`, {\n                    initialValue: k === 0 ? 1 : undefined,\n                    rules: [\n                      { required: true, message: '请输入周期数' },\n                      { type: 'integer', message: '请输入 1 - 10000000 之间的整数' },\n                      { validator: (rule, value, callback) => checkCycles(rule, value, callback, k) }\n                    ]\n                  }]"}],staticStyle:{"width":"120px"},attrs:{"min":1,"max":10000000,"disabled":""},on:{"change":_vm.validateFavorablePriceCycles}})],1)],1),_c('a-col',{attrs:{"span":_vm.favorablePriceSpanSize}},[_c('a-form-item',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([("base_product_favorable_prices[" + k + "].distributor_price"), {
                    rules: [
                      { required: true, message: '请输入标准价' },
                      { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                    ]
                  }]),expression:"[`base_product_favorable_prices[${k}].distributor_price`, {\n                    rules: [\n                      { required: true, message: '请输入标准价' },\n                      { type: 'number', message: '请输入 0 - 10000000 之间的数字' }\n                    ]\n                  }]"}],staticStyle:{"width":"120px"},attrs:{"min":0,"max":10000000,"step":0.01,"precision":2}})],1)],1),_c('a-col',{attrs:{"span":_vm.favorablePriceSpanSize}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-form-item',[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([("base_product_favorable_prices[" + k + "].floor_price"), {
                        rules: [
                          { required: true, message: '请输入底价' },
                          { type: 'number', message: '请输入 0 - 10000000 之间的数字' }
                        ]
                      }]),expression:"[`base_product_favorable_prices[${k}].floor_price`, {\n                        rules: [\n                          { required: true, message: '请输入底价' },\n                          { type: 'number', message: '请输入 0 - 10000000 之间的数字' }\n                        ]\n                      }]"}],staticStyle:{"width":"120px"},attrs:{"min":0,"max":10000000,"step":0.01,"precision":2}})],1)],1)],1)],1),(_vm.isAllowSetRetailPrice)?_c('a-col',{attrs:{"span":_vm.favorablePriceSpanSize}},[_c('a-input-group',{attrs:{"compact":""}},[_c('a-form-item',[_c('a-space',[_c('a-input-number',{directives:[{name:"decorator",rawName:"v-decorator",value:([("base_product_favorable_prices[" + k + "].retail_price"), {
                        rules: [
                          { type: 'number', message: '请输入 0 - 10000000 之间的数字' },
                          { validator: function (rule, value, callback) { return _vm.checkRetailPrice(rule, value, callback, k); } }
                        ]
                      }]),expression:"[`base_product_favorable_prices[${k}].retail_price`, {\n                        rules: [\n                          { type: 'number', message: '请输入 0 - 10000000 之间的数字' },\n                          { validator: (rule, value, callback) => checkRetailPrice(rule, value, callback, k) }\n                        ]\n                      }]"}],staticStyle:{"width":"120px"},attrs:{"min":0,"max":10000000,"step":0.01,"precision":2}})],1)],1)],1)],1):_vm._e()],1)})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }